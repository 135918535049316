// $feldman-text: #7cb763;
// $feldman-dark: #0b2b24;
// $feldman-primary: #4c8524;
// $feldman-secondary: #163614;
// $feldman-gray: #344444;

// * {
//   font-family: Arial, Helvetica, sans-serif;
// }

// .header {
//   height: 72px;
//   width: 100%;
//   position: fixed;
//   top: 0;
//   left: 0;
//   background-color: $feldman-dark;
//   display: flex;
//   justify-content: space-between;
//   z-index: 1000;

//   .navigation-wrapper-expanded {
//     position: fixed;
//     visibility: hidden;
//     height: 100%;
//     opacity: 0;
//     left: -100px;
//     border-right: 1px solid black;
//     background-color: $feldman-dark;
//     transition: left .5s, opacity ease-out .5s;

//     @media screen and (max-width: 600px) {
//       visibility: visible;

//       &.expanded {
//         left: 0;
//         opacity: 1;
//         display: flex;
//         flex-direction: row;

//         .navigation {
//           display: flex;
//           flex-direction: column;

//           li {
//             margin-bottom: 10px;
//           }
//         }
//       }
//     }
//   }

//   .navigation-wrapper, .navigation-wrapper-expanded {

//     .mobile-expand {
//       display: none;
//       margin: 16px 10px;

//       .mobile-expand-button {
//         margin: 0 10px;
//         border: 1px solid $feldman-primary;
//         padding: 10px;
//         text-decoration: none;
//         background-color: black;
//         width: 40px;
//         display: block;
//         text-align: center;
//         color: $feldman-primary;
//         transition: 0.5s;

//         &:hover {
//           border: 1px solid black;
//           background-color: $feldman-primary;
//           color: black;
//           cursor: pointer;
//         }
//       }

//       @media screen and (max-width: 600px) {
//         display: block;
//         align-items: center;
//       }
//     }

//     .navigation {
//       list-style-type: none;
//       display: flex;
//       align-items: center;
//       padding-left: 10px;
  
//       li a {
//         margin: 0 10px;
//         border: 1px solid $feldman-primary;
//         padding: 10px;
//         text-decoration: none;
//         background-color: black;
//         width: 82px;
//         display: block;
//         text-align: center;
//         color: $feldman-primary;
//         transition: 0.5s;
  
//         &:visited {
//           color: inherit;
//         }
  
//         &:hover {
//           border: 1px solid black;
//           background-color: $feldman-primary;
//           color: black;
//         }
//       }

//       @media screen and (max-width: 600px) {
//         display: none;
//       }
//     }
//   }

//   .social-media {
//     display: flex;
//     list-style-type: none;
//     width: 120px;
//     justify-content: space-between;
//     align-items: center;
//     margin-right: 20px;
//     border: 1px solid $feldman-primary;
//     padding: 10px;
//     background-color: black;

//     li {
//       height: 20px;
//       width: 20px;
//       border: 1px dashed $feldman-primary;
//       border-radius: 50%;
      
//       a {
//         color: $feldman-primary;
//       }

//       svg {
//         font-size: 20px;
//         &.free {
//           font-size: 16px;
//           margin-left: 3px;
//           margin-top: 2px;        
//         }
//       }
//     }
//   }
// }

// .photo-container {
//   z-index: 500;
//   position: fixed;
//   top: 72px;
//   width: 100%;
//   height: 174px;
//   padding: 0 20px;
//   background-color: aliceblue;
//   background-color: rgba(19, 27, 70, 0.582);
//   background-image: linear-gradient(0deg, rgba(0,0,0,0.9), rgba(12, 18, 46, 0.7));

//   .photo {
//     display: inline-block;
//     margin-top: 15px;  
//   }

//   h1 {
//     font-family: 'Courier New', Courier, monospace;
//     display: inline-block;
//     vertical-align: top;
//     margin: 10px;

//     @media screen and (max-width: 400px) {
//       font-size: 24px;
//     }

//     @media screen and (max-width: 350px) {
//       font-size: 18px;
//     }

//     @media screen and (max-width: 350px) {
//       font-size: 16px;
//     }
//   }
// }

// .main {
//   margin-top: 246px;
//   padding: 10px 20px;
// }

// .feldman-card-wrapper {
//   width: 100%;
//   height: 130px;
//   display: inline-flex;
//   margin-bottom: 20px;
//   transition: .5s;
//   position: relative;

//   // This is unfortunately kind of ugly.  Replace with cleaner animation.
//   // &:hover {
//   //   border: 1px solid black;
//   //   background-color: $feldman-primary;
//   //   color: black;

//   //   .feldman-card-img {
//   //     border: 1px dotted black;

//   //     svg {
//   //       color: black;
//   //     }
//   //   }
//   // }

//   .feldman-card-img {
//     height: 105px;
//     width: 105px;
//     border: 1px dotted $feldman-primary;
//     transition: .5s;

//     img {
//       width: 105px;
//       object-fit: cover;
//     }

//     svg {
//       width: 85px;
//       height: 85px;
//       padding: 10px;
//       color: $feldman-primary;
//       transition: .5s;
//     }
//   }

//   .feldman-card-body {
//     margin-left: 10px;
//     overflow-y: auto;    
//     user-select: none;  

//     .feldman-card-title {
//       font-weight: bold;
//       text-decoration: underline;
//       display: flex;
//       justify-content: space-between;
//       margin-bottom: 10px;
//     }

//     .feldman-card-description {
//       word-wrap: break-word;
//     }
//   }
// }

// p a {
//   color: $feldman-primary;
// }

// .feldman-page-container {
//   position: relative;
// }

// .feldman-page-container-enter {
//   opacity: 0;
//   top: -20px;
// }

// .feldman-page-container-enter-active {
//   opacity: 1;
//   top: 0;
//   transition: 1s;
// }

// .feldman-transition {
//   position: relative;
// }

// .feldman-card-ani-0-enter {
//   opacity: 0;
//   left: -10px;
// }

// .feldman-card-ani-0-enter-active {
//   opacity: 1;
//   left: 0;
//   transition: 1.2s;
// }

// .feldman-card-ani-1-enter {
//   opacity: 0;
//   left: 10px;
// }

// .feldman-card-ani-1-enter-active {
//   opacity: 1;
//   left: 0;
//   transition: .4s;
// }

// .feldman-card {
//   background-color: $feldman-dark;
//   border: 1px solid $feldman-primary;
//   border-radius: 10px;
//   padding: 10px;
//   // unfortunate hack, I didn't realize box-sizing was content-box until too late
//   // TODO: every div should use border-box, not just the feldman-card
//   box-sizing: border-box;
//   // kill links
//   * {
//     text-decoration: none;
//     color: $feldman-text;
//   }
// }

a {
  color: inherit;
  text-decoration: inherit;
}